body {
  margin: 0;
 font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Open Sans";
  src:  url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
}
