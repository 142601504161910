@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
@import "./fontawesome/scss/fontawesome.scss";
@import "fontawesome/scss/solid";

body {
  font-family: "Open Sans", sans-serif;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: transparent  url('../cloudbackground.svg') top right no-repeat;
  background-size: cover;
  background-position-x: 0;
  background-position-y: 0;
  background-color: rgba(0, 157, 255, 0.26);
}