.stripe-container {
  width: calc(300px - 24px);
  border: 1px solid #BFD7E6;
  padding: 10px 12px 10px 12px;
  border-radius: 2px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

.stripe-container::placeholder {
  font-weight: 400;
}

.StripeElement--invalid {
  background-color: #FFF4F4;
  border: 1px solid #FF0000;
}